﻿require('jquery');
require('jquery-validation');
require('jquery-validation-unobtrusive');

$(document).ready(function () {
    var anotherRetry = parseInt($("#NumberOfRetry").val()) + 1;
    $("#NumberOfRetry").val(anotherRetry);

    $("#number").on('input', function () {
        var newNumber = $("#number").val();
        var paddedValue = ((newNumber.toString() + "000000").slice(0, 6));
        $(".credit-card-icon").removeClass("selected");
        if (paddedValue.match("4.{5}")) {
            $("#visa").addClass("selected");
            $("#card-issuer").value = "Visa"; 
        }
        if (paddedValue.match("6011(0[0-9]|[2-4][0-9]|74|7[7-9]|8[6-9]|9[0-9])|(64[4-9].{3})|(65.{4})")) {
            $("#discover").addClass("selected");
            $("#card-issuer").value = "Discover"; 
        }
        if (paddedValue.match("5[1-5].{4}")) {
            $("#mastercard").addClass("selected");
            $("#card-issuer").value = "Mastercard"; 
        }
        if (paddedValue.match("^3[47][0-9]{4}$")) {
            $("#amex").addClass("selected");
            $("#card-issuer").value = "Amex"; 
        }
    });

    if (window.innerWidth < 768) {
        $("#discover").css("margin-right", "135px");
    }

    /* needed to show validation if there is an error */
    $("#name").keyup(function () {
        $("#name-error").css("color", "red");
    });

    $("#address").keyup(function () {
        $("#address-error").css("color", "red");
    });

    $("#city").keyup(function () {
        $("#city-error").css("color", "red");
    });

    $("#zip").keyup(function () {
        $("#zip-error").css("color", "red");
    });

    $("#number").keyup(function () {
        $("#number-error").css("color", "red");
    });

    $("#cvv").keyup(function () {
        $("#cvv-error").css("color", "red");
    });
    /* end of validation logic */
        
    // Function for changing background color of header on scroll
    $(function () {

        $(window).on("scroll", function () {
            var scroll = $(window).scrollTop();
            if (scroll > 10) {
                $("header").addClass("active");
            } else {
                //remove the background property so it comes transparent again (defined in your css)
                $("header").removeClass("active");
            }
        });

        var disclosureAuth;

        $.fn.changeButtonState = function () {
            if (!$('#paymentForm').valid() || !disclosureAuth) {
                $('button.button').prop('disabled', 'disabled');
            } else if ($('#paymentForm').valid() && disclosureAuth) {
                //If there is text in the input, then enable the button
                $('button.button').prop('disabled', false);
            }
        }

        $.fn.checking = function () {
            if ($('#disclosure-check').is(':checked')) {
                disclosureAuth = true;
            }
            else {
                disclosureAuth = false;
            }
            $(this).changeButtonState();
        };

        // Sets checkbox as unchecked by default
        if ($("#disclosure-check").length) {
            $('#disclosure-check').attr('checked', false);
            $(this).changeButtonState();
        }
        $('#disclosure-check').bind('change', function () {
            $(this).checking();
        });

        $('#paymentForm').keyup(function () {
            $(this).changeButtonState();
        });

        $('#login').on('click', function (e) {
            if ($('#loginForm').valid()) {
                var loginVerbiage = "Logging In...";
                startPreloader(loginVerbiage);
            }
        });

        $('#submitpayment').on('click', function (e) {
            if ($('#paymentForm').valid()) {
                var submitVerbiage = "Submitting Payment...";
                startPreloader(submitVerbiage);
            }
        });

        function startPreloader(verbiage) {
            var preLoader = "<div id='loader-wrapper' class='calc'><div id= 'loader-content'><h2>" + verbiage + "</h2></div><div id='loader' class='large'></div><div class='loader-section section-left'></div><div class='loader-section section-right'></div></div>";
            $(".container-uhm").before(preLoader);
        }

        $("#open-modal").on("click", function () {
            $("#disclosure-modal").css({
                "visibility": "visible", "opacity": 1
            });
            $("body").addClass("modal-open");
        });

        $("#close-modal").on("click", function () {
            $("#disclosure-modal").css({
                "visibility": "hidden", "opacity": 0
            });
            $("body").removeClass("modal-open");
        });

        // Function for checking if date on credit card has expired
        function checkDate() {
            var now = new Date();
            var mm = parseInt($("#Card_ExpMonth").val());
            var yyyy = parseInt($("#Card_ExpYear").val()) + 2000;
            var mmm = now.getMonth() + 1;
            var yyyyy = now.getFullYear();
            if ((mm < mmm && yyyy === yyyyy)) {
                if (!$("#expiration-message").length) {
                    $("#expiration-error").append("<span id='expiration-message'>The credit card is expired.</span>");
                    $("#expiration-message").css("color", "red");
                    console.log("success");
                }
            } else {
                $("#expiration-message").remove();
            }
        }
        $("#Card_ExpMonth").on("change", function (e) {
            checkDate();
        });
        $("#Card_ExpYear").on("change", function (e) {
            checkDate();
        });

    });
});