exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.i(require("-!../../node_modules/css-loader/index.js??ref--4-1!../../node_modules/uhm-unity-theme-core/dist/unity-theme-core.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--4-1!./LoginPage.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--4-1!./PaymentPage.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--4-1!./ThankYouPage.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--4-1!./AlreadyPaidPage.css"), "");
exports.i(require("-!../../node_modules/css-loader/index.js??ref--4-1!../../node_modules/font-awesome/css/font-awesome.css"), "");

// module
exports.push([module.id, "/*header CSS*/\r\nheader {\r\n    position: fixed;\r\n    display: block;\r\n    width: 100%;\r\n    top: 0;\r\n    left: 0;\r\n    z-index: 2;\r\n    background-color: transparent;\r\n    padding: 10px 0px;\r\n    -webkit-transform: translateX(0);\r\n    -moz-transform: translateX(0);\r\n    transform: translateX(0);\r\n    transition: .2s all;\r\n    -moz-transition: .2s all;\r\n    -webkit-transition: .2s all;\r\n    -o-transition: .2s all;\r\n}\r\n\r\n    header .logo-uhm-holder {\r\n        float: left;\r\n        display: block;\r\n        width: 100%;\r\n        text-align: center;\r\n        margin: 0 auto;\r\n    }\r\n\r\n    header img.logo-uhm {\r\n        width: 190px;\r\n        height: auto;\r\n        margin: 0px;\r\n    }\r\n\r\n@media screen and (min-width: 1024px) {\r\n    header img.logo-uhm {\r\n        width: 250px;\r\n    }\r\n}\r\n\r\n/* for background of header on scroll */\r\n.active {\r\n    background: rgb(255, 255, 255);\r\n    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 10px;\r\n}\r\n\r\nfooter {\r\n    margin-top: 0px;\r\n}\r\n\r\nh3 {\r\n    font-family: Raleway;\r\n    font-weight: bold;\r\n}\r\n", ""]);

// exports
